import { useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import shoes_stim from "./stim_images/Shoes_stim.PNG";
import butter_stim from "./stim_images/Butter_stim.PNG";
import stim_example from "./stim_images/stim_example.jpeg";
import { stimClicked, gameError } from '../../services/api';

function Stimulus(props) {
    const [stim, setStim] = useState(null);
    const [display, setDisplay] = useState(false);

    useEffect(() => {

        // Prefetch images
        const images = [shoes_stim, butter_stim];
        images.forEach((image) => {
            const link = document.createElement('link');
            link.rel = 'prefetch';
            link.href = image;
            link.as = 'image';
            document.head.appendChild(link);
        });

        const timer = setTimeout(() => {
            if (props.stim === "shoes_stim")
                setStim(shoes_stim);
            else if (props.stim === "butter_stim")
                setStim(butter_stim);
            else
                setStim(stim_example);
            setDisplay(true);
        }, props.whenToDisplay);
        return () => clearTimeout(timer); // This will clear the timer when the component unmounts
    }, [props.whenToDisplay, props.stim, props.whenToDisplay]);

    const handleClick = (event) => {
        const imgRect = event.target.getBoundingClientRect();
        const clickX = event.clientX;
        const clickY = event.clientY;

        const lowerLeftCornerX = imgRect.left;
        const lowerLeftCornerY = imgRect.bottom;
        const tolerance = 25; // Adjust this value to increase or decrease the clickable area


        // Define the area within the image
        const offsetX = 20; // Horizontal offset from the right edge of the image
        const offsetY = 20; // Vertical offset from the top edge of the image
        const areaTopRightX = imgRect.right - offsetX;
        const areaTopRightY = imgRect.top + offsetY;
        const areaWidth = 200; // Width of the clickable area
        const areaHeight = 50; // Height of the clickable area

        if (clickX >= lowerLeftCornerX && clickX <= lowerLeftCornerX + tolerance &&
            clickY >= lowerLeftCornerY - tolerance && clickY <= lowerLeftCornerY) {
            if (process.env.NODE_ENV === 'development') {
                console.log('X clicked!');
            }
            stimClicked(clickX, clickY, true, false)
        } else if (clickX >= areaTopRightX - areaWidth && clickX <= areaTopRightX &&
            clickY >= areaTopRightY && clickY <= areaTopRightY + areaHeight) {
            if (process.env.NODE_ENV === 'development') {
                console.log('Buy Now clicked!');
            }
            stimClicked(clickX, clickY, false, true)
        } else {
            if (process.env.NODE_ENV === 'development') {
                console.log('Stim clicked!');
            }
            stimClicked(clickX, clickY, false, false)
        }
    };

    const handleImageLoad = () => {
        Sentry.captureMessage("Stim image loaded successfully", {
            level: "info",
            extra: { stim },
        });
    };

    const handleImageError = (error) => {
        Sentry.captureException(new Error("Stim image failed to load"), {
            extra: { stim, error },
        });
        gameError("Stim image failed to load", error);
    };

    return (
        display && stim && (
            <div className="text-center">
                <img 
                    src={stim} 
                    alt="Stimulus" 
                    width="600" 
                    height="10" 
                    onClick={handleClick}
                    onLoad={handleImageLoad}
                    onError={handleImageError}>
                </img>
            </div>
        )
    );
}

export default Stimulus;