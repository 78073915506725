import { useEffect, useState } from "react";
import Box from "../Box";
import words from "../../words";
import newWords from "../../newWords";
import allWords from "../../allWords";
import bigWords from "../../bigWords";
import { gameGuess, gameEnd } from '../../services/api';
import GameOutcomeModal from "../GameOutcomeModal";


// const correct = 'CANOE'
{/*words[Math.floor(Math.random() * words.length - 1)].toUpperCase();*/ }
let defaulBoard = [];
let defaultLetters = [];

"abcdefghijklmnopqrstuvwxyz".split("").forEach((i) => {
  defaultLetters[i] = "";
});

for (let i = 0; i < 6; i++) {
  defaulBoard.push([]);
  for (let j = 0; j < 5; j++) {
    defaulBoard[i].push(["", ""]);
  }
}

function findMultipleMatchesWithPositions(str1, str2) {
  const frequencyMap = {};
  const matchingLetters = [];

  // Create frequency map for str2
  for (const char of str2) {
    if (frequencyMap[char]) {
      frequencyMap[char]++;
    } else {
      frequencyMap[char] = 1;
    }
  }

  // Check str1 against the frequency map and store positions
  for (let i = 0; i < str1.length; i++) {
    const char = str1[i];
    if (frequencyMap[char] > 1) {
      const positions = [];
      for (let j = 0; j < str2.length; j++) {
        if (str2[j] === char) {
          positions.push(j);
        }
      }
      matchingLetters.push({ letter: char, positions });
    }
  }

  return matchingLetters;
}
// Example usage:
/*
const str1 = "TALES";
const str2 = "STEAL";
const result = findMultipleMatchesWithPositions(str1, str2);
console.log(result); // Output: [{ letter: 'A', positions: [1, 3] }, { letter: 'E', positions: [2, 4] }]
*/

function Board(props) {
  const [letters, setLetters] = useState(defaultLetters);
  const [board, setBoard] = useState(defaulBoard);
  const [changed, setChanged] = useState(false);
  const [row, setRow] = useState(0);
  const [col, setCol] = useState(0);
  const [win, setWin] = useState(false);
  const [lost, setLost] = useState(false);
  const [message, setMessage] = useState("");
  const correct = props.correctWord;
  const qCode = props.qCode;
  const [gameEnded, setGameEnded] = useState(localStorage.getItem('gameEnded') === 'true');


  useEffect(() => {
    if (win || lost) {
      console.log("Game ended!");
    } else {
      if (props.clicks !== 0) {
        if (props.letter === "DEL") {
          setCol(col === 0 ? 0 : col - 1);
          setBoard((prevBoard) => {
            prevBoard[row][col === 0 ? 0 : col - 1][0] = "";
            return prevBoard;
          });
        } else {
          setBoard((prevBoard) => {
            if (col < 5) {
              if (props.letter !== "ENTER") {
                prevBoard[row][col][0] = props.letter;
                setCol(col + 1);
              } else {
                props.error("Words are 5 letters long!");
                let word = "";
                for (let i = 0; i < 5; i++) {
                  word += prevBoard[row][i][0];
                }
                gameGuess(word, (row + 1), false, "Words are 5 letters long!")
                  .then(response => {
                    console.log('Guess response logged', response);
                  })
                  .catch(error => {
                    console.error('Error logging guess', error);
                  });
                setTimeout(() => {
                  props.error("");
                }, 1000);
              }
            } else {
              if (props.letter === "ENTER") {
                let correctLetters = 0;
                let word = "";
                for (let i = 0; i < 5; i++) {
                  word += prevBoard[row][i][0];
                }
                if (words.includes(word.toLowerCase()) || newWords.includes(word.toLowerCase()) || allWords.includes(word.toLowerCase()) || bigWords.includes(word.toLowerCase())) {
                  const matched_more_than_once = findMultipleMatchesWithPositions("TALES", word);
                  let greenies = [];
                  for (let i = 0; i < 5; i++) {
                    if (correct[i] === prevBoard[row][i][0]) {
                      greenies.push(correct[i]);
                      prevBoard[row][i][1] = "C";
                      correctLetters++;
                    } else if (correct.includes(prevBoard[row][i][0]))
                      prevBoard[row][i][1] = "E";
                    else prevBoard[row][i][1] = "N";
                    setRow(row + 1);
                    if (row === 5 && i === 4) {
                      setLost(true);
                      localStorage.setItem('gameEnded', 'true');
                      // let word = "";
                      gameEnd('lose', word, (row + 1))
                        .then(response => {
                          console.log('Game ended!!!', response);
                        })
                        .catch(error => {
                          console.error('Error logging ending game', error);
                        });
                      setTimeout(() => {
                        setMessage(`It was ${correct}`);
                      }, 750);
                    }

                    setCol(0);
                    setLetters((prev) => {
                      prev[board[row][i][0]] = board[row][i][1];
                      return prev;
                    });
                  }

                  const matches = greenies
                    .map(greenie => {
                      const match = matched_more_than_once.find(m => m.letter === greenie);
                      return match ? { greenie, positions: match.positions } : null;
                    })
                    .filter(Boolean);
                  // console.log(matches); // Output: Array of objects with greenie and positions
                  // Iterate over matches array
                  matches.forEach(match => {
                    //console.log(`Greenie: ${match.greenie}, Positions: ${match.positions}`);
                    const position = "TALES".indexOf(match.greenie);
                    //console.log(`Position: ${position}`);
                    for (const subposition of match.positions) {
                      if (subposition !== position) {
                        prevBoard[row][subposition][1] = "N";
                      }
                    }
                  });
                  setChanged(!changed);

                  if (correctLetters === 5) {
                    setWin(true);
                    localStorage.setItem('gameEnded', 'true');
                    gameEnd('win', word, (row + 1))
                      .then(response => {
                        console.log('Game ended with a winner!', response);
                      })
                      .catch(error => {
                        console.error('Error logging ending game', error);
                      });
                    setTimeout(() => {
                      setMessage("You WIN");
                    }, 750);
                  } else {
                    gameGuess(word, (row + 1), true, "")
                      .then(response => {
                        console.log('Guess response logged', response);
                      })
                      .catch(error => {
                        console.error('Error logging guess', error);
                      });
                  }
                  return prevBoard;
                } else {
                  props.error("Word not in dictionary");
                  gameGuess(word, (row + 1), false, "Word not in dictionary")
                    .then(response => {
                      console.log('Guess response logged', response);
                    })
                    .catch(error => {
                      console.error('Error logging guess', error);
                    });
                  setTimeout(() => {
                    props.error("");
                  }, 1000);
                }
              }
            }
            return prevBoard;
          });
        }
      }
    }
  }, [props.clicks]);

  useEffect(() => {
    props.letters(letters);
  }, [changed]);

  return (
    <div className="px-10 py-5 grid gap-y-1 items-center w-100 justify-center">
      {board.map((row, key) => {
        return (
          <div key={key} className="flex gap-1 w-fit">
            {row.map((value, key) => (
              <Box key={key} value={value[0]} state={value[1]} pos={key} />
            ))}
          </div>
        );
      })}
      {lost || win ? <GameOutcomeModal outcome={message} qCode={qCode} /> : ""}
      {/*   {lost || win ?  <p> <strong>{message}!  <br/> Please go back to the questionaire <br/> and enter this code at the prompt: {qCode}  </strong></p> : ""} */}
    </div>
  );
}

export default Board;
