import React from 'react';
import styles from './style.module.css';

function GameOutcomeModal({ outcome, qCode }) {
    return (
      <div>
        <div className={styles.modalOverlay}>
          <p className={styles.modalOutcomeText}>The game has ended. The word was 'tales'. Please go back to the questionaire and enter this code at the prompt: <strong> {qCode}</strong></p>
          {/* No close button or functionality */}
        </div>
      </div>
    );
  }

  export default GameOutcomeModal;