import React from 'react';

function GameEnded() {
  return (
    <div className={"app dark:bg-zinc-800"}>
      <div className={"flex justify-center items-center h-screen"}>
        <h1 className={"text-4xl text-black"}>Game has ended</h1>
      </div>
    </div>
  );
};

export default GameEnded;