import { useState, useEffect } from "react";
import "./App.css";
import Game from "./components/Game";
import GameEnded from './components/GameEnded';

// ...
import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: "https://e4b307f7b3b90b3fbf7fe7c02b7c1b15@o59337.ingest.us.sentry.io/4508059323203584",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
  const [pid, setPid] = useState(' ');
  const [qCode, setQCode] = useState(' ');
  const [correctWord, setCorrectWord] = useState(' ');
  const [stim, setStim] = useState(null);
  const [whenToDisplay, setWhenToDisplay] = useState(5000);
  const [gameEnded, setGameEnded] = useState(localStorage.getItem('gameEnded') === 'true');
  const [isMobile, setIsMobile] = useState(false);
  const [screenSize, setScreenSize] = useState({ width: 0, height: 0 });

  const darkHandler = (dark) => {
    {/*
    if (dark) document.documentElement.classList.add("dark");
    else document.documentElement.classList.remove("dark");
  */}
    document.documentElement.classList.add("dark");
    document.documentElement.classList.remove("dark");
  };

  useEffect(() => {
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    setPid(params.get('pptID'));
    setQCode(params.get('qCode'));
    // correct word is hardcoded to "TALES", but it can be parsed from the URL, or fetched from an API, etc
    setCorrectWord("TALES");
    // Randomly set sstim to either "butter_stim" or "shoes_stim"
    setStim(Math.random() < 0.5 ? "butter_stim" : "shoes_stim");
    setWhenToDisplay(3000);

    // Detect if the user is on a mobile device
    const checkIfMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
        return true;
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return true;
      }
      return false;
    };

    // Get screen size
    const getScreenSize = () => {
      return {
        width: window.innerWidth,
        height: window.innerHeight
      };
    };
    setIsMobile(checkIfMobile());
    setScreenSize(getScreenSize());
  }, []);


  // COMMENT THIS TIMER OUT IF TO ENABLE THE PREVENTION OF GAME RELOADING
  // ie WHEN WE GO LIVE - WE WANT TO PREVENT THE GAME FROM RELOADING AFTER DEVLEPING
  setTimeout(() => {
    localStorage.setItem('gameEnded', 'false');
  }, 1000);

  if (gameEnded) {
    console.log("Game ended!");
    return <GameEnded />;
    /*
    return (
      <div className={"app dark:bg-zinc-800"}>
        {pid && <Game darkness={darkHandler} participantId={pid} correctWord={correctWord} stim={stim} whenToDisplay={whenToDisplay} />}
      </div>
    );
    */
  } else {
    return (
      <div className={"app dark:bg-zinc-800"}>
        {pid && qCode && <Game darkness={darkHandler} isUsingMobile={isMobile} screenSizeWidth={screenSize.width} screenSizeHeight={screenSize.height} participantId={pid} correctWord={correctWord} stim={stim} whenToDisplay={whenToDisplay} qCode={qCode} />}
      </div>
    );
  }
}

export default App;
