import { useState, useEffect } from "react";
import Board from "../Board";
import Error from "../Error";
import Help from "../Help";
import KeyBoard from "../KeyBoard";
import Modal from "../Modal";
import NavBar from "../NavBar";
import styles from "./style.module.css";
import Stimulus from "../Stimulus";
import { gameStart } from '../../services/api';
import * as Sentry from "@sentry/browser";

function Game(props) {
  const [letter, setLetter] = useState();
  const [changed, setChanged] = useState(false);
  const [letters, setLetters] = useState({});
  const [help, setHelp] = useState(false);
  const [clicked, setClicked] = useState(0);
  const [error, setError] = useState("");
  const [dark, setDark] = useState(false);
  const [data, setData] = useState(null);
  const [participantId, setParticipantId] = useState(null);

  const onClickDown = (event) => {
    if (event.key == "Enter") {
      setLetter("ENTER");
      setClicked(clicked + 1);
    } else if (event.key == "Backspace") {
      setLetter("DEL");
      setClicked(clicked + 1);
    } else if ("abcdefghijklmnopqrstuvwxyz".includes(event.key.toLowerCase())) {
      setLetter(event.key.toUpperCase());
      setClicked(clicked + 1);
    }
  };

  const loadData = async () => {
    try {
      const data = await gameStart(props.participantId, props.correctWord, props.stim, props.whenToDisplay, props.isUsingMobile, props.screenSizeWidth, props.screenSizeHeight);
      setData(data);
    } catch (error) {
      console.error('Error starting game', error);
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    setParticipantId(props.participantId);
  }, []);

  useEffect(() => {
    if (participantId) {
      loadData();
    }
  }, [participantId]);

  useEffect(() => {
    window.addEventListener("keydown", onClickDown);
    return () => window.removeEventListener("keydown", onClickDown);
  });

  useEffect(() => {
    props.darkness(dark);
  }, [dark]);

  const keyHandler = (letterValue) => {
    setLetter(letterValue);
    setClicked(clicked + 1);
  };
  const LettersHandler = (lettersValue) => {
    setLetters(lettersValue);
    setChanged(!changed);
  };
  return (
    <>
      {help && (
        <Modal title="How to play!" help={setHelp}>
          {" "}
          <Help />{" "}
        </Modal>
      )}
      {error && <Error>{error}</Error>}
      <div className={styles.game}>
        {/*<NavBar help={setHelp} darkness={setDark} dark={dark} /> */}
        <hr />
        <Board
          letter={letter}
          clicks={clicked}
          letters={LettersHandler}
          error={setError}
          correctWord={props.correctWord}
          qCode={props.qCode}
        />
        <Stimulus whenToDisplay={props.whenToDisplay} stim={props.stim} />
        <br />
        <KeyBoard keyHandler={keyHandler} letters={letters} changed={changed} />
      </div>
    </>
  );
}

export default Game;
