// Import necessary modules
import axios from 'axios';
import { saveAs } from 'file-saver';

const API_URL = 'https://api.c-wordle.latis.umn.edu/api/v1/results/';
const getHeaders = () => {
    return {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "X-API-KEY": "kfi9DGKngsMUI4Umr6Iz"
    };
};


function postResultToBackend(request_body) {
    fetch('https://api.c-wordle.latis.umn.edu/api/v1/results/', {
        method: "POST",
        cache: "default",
        body: JSON.stringify(request_body),
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "X-API-KEY": "kfi9DGKngsMUI4Umr6Iz"
        }
    }).then(response => {
        console.log(response.status)
        if (response.status !== 200) {
            console.log('Looks like there was a problem. Status Code: ' + response.status);
            // throw new Error("Post to endpoint failed with code" + response.status);
            return;
        }
    })
}

// Helper function to encapsulate API post call
const postData = async (url, data, headers) => {
    var request_body = {
        "application": "c-wordle",
        "key": "c-wordle",
        "token": "",
        "run_id": "c-wordle-run-id",
        "worker_id": participantId,
        "task_id": "c-wordle-task-id",
        "is_debug": true,
        "payload": data
    };
    try {
        const response = await axios.post(url, request_body, { headers });
        console.log('API response:', response.data);
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 404) {
            console.error('Error 404: Resource not found');
        } else {
            console.error('Error fetching data', error);
        }
        throw error;
    }
};


let participantId = null;
let isMobile = false;
let screenSizeWidth = 0;
let screenSizeHeight = 0;


// Initialize an array to store CSV data in memory
const csvData = [
    ['Participant ID', 'Timestamp', 'Event', 'Correct Word', 'Ad', 'When To Display', 'isUsingMobile', 'screenSizeWidth', 'screenSizeHeight', 'Clicked X', 'Clicked Y', 'Is On The X', 'Is On The Buy Now', 'Guess', 'Word Row', 'Is Valid', 'Reason If Not Valid', 'Outcome']
];

// Function to convert data to CSV format
const convertToCSV = (data) => {
    // console.log(data);
    return data.map(row => {
        // Check if each row is an array
        if (Array.isArray(row)) {
            return row.join(",");
        } else {
            console.error("Row is not an array:", row);
            return ""; // Handle the case where row is not an array
        }
    }).join("\n");
};

// Function to save CSV data
const saveCSV = (csvContent, fileName) => {
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, fileName);
};

export const gameStart = async (theParticipantId, correctWord, ad, whenToDisplay, isUsingMobile, screenSizeWidth, screenSizeHeight) => {
    participantId = theParticipantId;
    isMobile = isUsingMobile;
    screenSizeWidth = screenSizeWidth;
    screenSizeHeight = screenSizeHeight;
    const data = {
        participantId: participantId,
        timestamp: new Date().toISOString(),
        event: 'start',
        payload: {
            correctWord: correctWord,
            ad: ad,
            whenToDisplay: whenToDisplay,
            isUsingMobile: isUsingMobile,
            screenSizeWidth: screenSizeWidth,
            screenSizeHeight: screenSizeHeight
        }
    };
    // Prepare data for CSV
    const csvdata = [
        data.participantId,
        data.timestamp,
        data.event,
        data.payload.correctWord,
        data.payload.ad,
        data.payload.whenToDisplay,
        data.payload.isUsingMobile,
        data.payload.screenSizeWidth,
        data.payload.screenSizeHeight,
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        ''
    ];
    // Add data to in-memory CSV array
    //csvData.push(csvdata);
    console.log('data:', data);
    //postResultToBackend(data);
    /*
    try {
        const response = await axios.post(API_URL, data, {
            headers: getHeaders()
        });
        console.log('API response:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching data', error);
        throw error;
    }
    */
    postData(API_URL, data, getHeaders());


};

export const stimClicked = async (clickedX, clickedY, isOnTheX, isOnTheBuyNow) => {
    const data = {
        participantId: participantId,
        timestamp: new Date().toISOString(),
        event: 'ad_clicked',
        payload: {
            clickedX: clickedX,
            clickedY: clickedY,
            isOnTheX: isOnTheX,
            isOnTheBuyNow: isOnTheBuyNow
        }
    };
    // Prepare data for CSV
    const csvdata = [
        data.participantId,
        data.timestamp,
        data.event,
        '',
        '',
        '',
        '',
        '',
        '',
        data.payload.clickedX,
        data.payload.clickedY,
        data.payload.isOnTheX,
        data.payload.isOnTheBuyNow
    ];
    // Add data to in-memory CSV array
    // csvData.push(csvdata);
    /*
    try {
        const response = await axios.post(API_URL, data, {
            headers: getHeaders()
        });
        console.log('API response:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching data', error);
        throw error;
    }
        */
    postData(API_URL, data, getHeaders());
};

export const gameGuess = async (theGuess, wordRow, isValidGuess, notValidReason) => {
    const data = {
        participantId: participantId,
        timestamp: new Date().toISOString(),
        event: 'guess',
        payload: {
            guess: theGuess,
            wordRow: wordRow,
            isValid: isValidGuess,
            reasonIfNotValid: notValidReason
        }
    };
    // Prepare data for CSV
    const csvdata = [
        participantId,
        new Date().toISOString(),
        'guess',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        theGuess,
        wordRow,
        isValidGuess,
        notValidReason,
        ''
    ];
    // Add data to in-memory CSV array
    // csvData.push(csvdata);
    /*
        try {
            const response = await axios.post(API_URL, data, {
                headers: getHeaders()
            });
            console.log('API response:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error fetching data', error);
            throw error;
        }
    */
    postData(API_URL, data, getHeaders());
};

export const gameEnd = async (outcome, theGuess, wordRow) => {
    const data = {
        participantId: participantId,
        timestamp: new Date().toISOString(),
        event: 'end',
        payload: {
            outcome: outcome,
            theGuess: theGuess,
            wordRow: wordRow
        }
    };

    const csvdata = [
        participantId,
        new Date().toISOString(),
        'end',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        theGuess,
        wordRow,
        '',
        '',
        outcome
    ];
    // Add data to in-memory CSV array
    // csvData.push(csvdata);

    // Read the CSV file content
    // const csvContent = convertToCSV(csvData);
    //saveCSV(csvContent, 'game-data.csv');
    /*
        try {
            const response = await axios.post(API_URL, data, {
                headers: getHeaders()
            });
            console.log('API response:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error fetching data', error);
            throw error;
        }
    */
    postData(API_URL, data, getHeaders());
};

export const gameError = async (theError, errorDesc) => {
    const data = {
        participantId: participantId,
        timestamp: new Date().toISOString(),
        event: 'gameError',
        payload: {
            theError: theError,
            errorDesc: errorDesc
        }
    };

    postData(API_URL, data, getHeaders());
};